<template>
  <div class="custom-card-box tip-box">
    <div class="tip-title">
      <img src="/temp/left.png" alt="logo" class="tip-img">
      <div class="title">{{ isShowNotes ? '主家待办事项' : '感恩告别仪式注意事项' }}</div>
      <img src="/temp/right.png" alt="logo" class="tip-img">
    </div>
    <div
      v-if="isShowNotes && data[0]"
      class="content-box"
    >
      <div
        class="content"
        v-html="data[0]"
      />
    </div>

    <div
      v-if="!isShowNotes && data[1]"
      class="content-box"
    >
      <div
        class="content"
        v-html="data[1]"
      />
    </div>

  </div>
</template>

<script>
export default {
  name: 'Tip',
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      isShowNotes: true,
      loopDataTimer: null
    }
  },
  created() {
    this.loopFetchData()
  },
  destroyed() {
    clearInterval(this.loopDataTimer)
  },
  methods: {
    loopFetchData() {
      this.loopFetchDataTimer = setInterval(this.changeShowNotes, 20000)
    },

    changeShowNotes() {
      this.isShowNotes = !this.isShowNotes
    }
  }
}
</script>
<style lang="less" scoped>
.tip-box {
  height: 100%;
  border-radius: 6px;

  .content-box {
    overflow: hidden;
    height: 600px;

    .content {
      height: 600px;
      -webkit-animation-name: move;
      -webkit-animation-duration: 20s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-direction: normal;
      -webkit-animation-timing-function: linear;
    }

    @-webkit-keyframes move {
      0% {
        margin-top: 600px;
      }

      100% {
        margin-top: -600px;
      }
    }
  }
}

.tip-title {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;

  .title {
    font-weight: bold;
    color: #ffd794ff;
  }

  .tip-img {
    width: 20px;
  }
}
</style>
