<template>
  <div class="user-info-box">
    <a-row :gutter="12" class="user-info-row">
      <a-col span="12" class="user-info-col">
        <div class="wechat-sub">
          <div class="wechat-sub-qr">
            <qrcode-vue
              v-if="qrCode"
              :value="qrCode"
              :size="200"
              level="H"
            />
          </div>
          <span>微信扫码，订购殡仪物品</span>
        </div>
      </a-col>
      <a-col span="12" class="user-info-col">
        <div class="user-info">
          <img
            v-show="data.images && data.images.length > 0"
            alt="img"
            v-for="(item, index) in data.images"
            :key="index"
            :src="item.url"
          >
          <img
            v-show="!data.images || data.images.length === 0"
            alt="img"
            src="/temp/user.png"
          >
          <div>
            <span class="user-label">负责人：</span>
            <span class="custom-word-break-all">{{ data.name }}</span>
          </div>
          <div>
            <span class="user-label">工号：</span>
            <span>{{ data.no }}</span>
          </div>
          <div>
            <span class="user-label">电话：</span>
            <span>{{ data.phone_number }}</span>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { findSubscribeOrderQrCodeUrl } from '@/api/hall'
export default {
  name: 'UserInfo',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    QrcodeVue: () => import('qrcode.vue')
  },
  data() {
    return {
      qrCode: ''
    }
  },
  created() {
    this.fetchQrCodeUrl()
  },
  methods: {
    fetchQrCodeUrl() {
      if (this.data.service_order_id === 0) {
        return
      }
      findSubscribeOrderQrCodeUrl(this.data.service_order_id).then((res) => {
        if (res.code === 0) {
          this.qrCode = res.data.qr_code_url
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.user-info-box {
  height: 100%;

  .user-info-row {
    height: 100%;

    .user-info-col {
      height: 100%;

      .wechat-sub {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .wechat-sub-qr {
          margin-bottom: 10px;
          padding: 10px;
          background-color: white;
        }
      }

      .user-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        img {
          margin-bottom: 10px;
          width: 100px;
          border-radius: 5px;
        }

        .user-label {
          color: #ffd794ff;
        }
      }
    }
  }
}
</style>
