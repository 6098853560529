<template>
  <div class="death-content">
    <img
      v-show="death.images && death.images.length > 0"
      alt="img"
      v-for="(item, index) in death.images"
      :key="index"
      :src="item.url"
    >
    <img
      v-show="!death.images || death.images.length === 0"
      alt="img"
      src="/temp/death.png"
    >
    <div class="death-name">
      <span>{{ death.name }}</span>
    </div>

    <div class="life-info">
      <div class="death-life-info-title"><span>生平简介：</span></div>
      <div class="death-life-info-content"><span> {{ death.life_info }}</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeathInfo',
  props: {
    death: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.death-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  img {
    width: 100%;
    min-height: 250px;
  }

  .death-name {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    font-size: 24px;
    border-radius: 6px;
    color: #ffd794ff;
    background-color: #37543e;
  }

  .life-info {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
  }
}
</style>

