<template>
  <div class="in-use-box">
    <no-data v-if="data.charge_person.service_order_id === 0" />
    <div v-else class="custom-height-100p">
      <a-row :gutter="[16,16]" class="in-use-row">
        <a-col span="5" class="in-use-col">
          <div class="custom-card-box death-info">
            <death-info :death="data.death_info" />
          </div>
        </a-col>

        <a-col span="19" class="in-use-col">
          <a-row :gutter="[16,16]" style="height: 270px;">
            <a-col span="24">
              <node :days="data.process_day_info" v-if="data.process_day_info" />
              <a-empty image="/temp/no_data.png" v-else>
                <span slot="description" style="color: #fff;"> 暂无数据 </span>
              </a-empty>
            </a-col>
          </a-row>

          <a-row :gutter="16" class="bottom-content-row">
            <a-col span="12" class="bottom-content-col">
              <tip :data="data.notices" />
            </a-col>
            <a-col span="12" class="bottom-content-col">
              <a-row class="time-box">
                <div class="custom-card-box time-content">
                  <time-info :data="data.time_info" />
                </div>
              </a-row>

              <a-row class="user-box">
                <div class="custom-card-box user-content">
                  <user-info :data="data.charge_person" />
                </div>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import DeathInfo from '@/views/halls/show/in_use/DeathInfo'
import Node from '@/views/halls/show/in_use/Node'
import Tip from '@/views/halls/show/in_use/Tip'
import TimeInfo from '@/views/halls/show/in_use/Time'
import UserInfo from '@/views/halls/show/in_use/UserInfo'
import NoData from '@/views/halls/show/in_use/NoData'
export default {
  name: 'HallInUseIndex',
  components: {
    NoData,
    DeathInfo,
    Node,
    Tip,
    TimeInfo,
    UserInfo
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.in-use-box {
  height: 100%;

  .in-use-row {
    height: 100%;
  }

  .in-use-col {
    display: flex;
    flex-direction: column;
    height: 100%;

    .death-info {
      height: 100%;
      border-radius: 6px;
    }

    .bottom-content-row {
      display: flex;
      align-items: stretch;
      margin-top: 10px;
      height: 688px;
      flex: 1;

      .bottom-content-col {
        .time-box {
          padding-bottom: 5px;
          height: 310px;

          .time-content {
            height: 100%;
            border-radius: 6px;
          }
        }

        .user-box {
          padding-top: 5px;
          height: 378px;

          .user-content {
            height: 100%;
            border-radius: 6px;
          }
        }
      }
    }
  }
}
</style>

