<template>
  <a-tabs :default-active-key="defaultActiveKey" v-if="days" size="large">
    <a-tab-pane
      v-for="day in days"
      :key="day.name"
      :tab="day.name"
    >
      <div class="node-content" v-show="day.processes && day.processes.length > 0">
        <div
          v-for="(node, index) in day.processes"
          :key="index"
          class="node-box"
          :style="{backgroundColor: node.is_next ? '#ffd794ff' : '', color: node.is_next ? '#323232FF' : ''}"
        >
          <div class="node-name">{{ node.name }}</div>
          <div>{{ node.is_finished ? '已完成' : '' }}</div>
          <div class="node-remark">{{ node.remark }}</div>
        </div>
      </div>
      <a-empty image="/temp/no_data.png" style="height: 180px;" v-if="!day.processes || day.processes.length === 0" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
export default {
  name: 'Node',
  props: {
    days: {
      type: Array,
      required: true
    }
  },
  computed: {
    defaultActiveKey() {
      for (const day of this.days) {
        if (day.is_today) {
          return day.name
        }
      }
      return this.days[0].name
    }
  }
}
</script>

<style lang="less" scoped>
.node-content {
  display: flex;
  justify-content: flex-start;

  .node-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    width: 190px;
    min-height: 180px;
    font-size: 20px;
    border-radius: 6px;
    text-align: center;
    background-color: #37543e;
    flex-direction: column;

    .node-name {
      font-size: 24px;
    }

    .node-remark {
      white-space: pre-wrap;
    }
  }
}

/deep/ .ant-tabs .ant-tabs-tab {
  font-size: 20px;
}
</style>

